.profile_image {
    display: flex;
}

@media only screen and (max-width: 600px) {
    .profile_image {
        display: block;

    }

}