.logoSearch {
  display: flex;
  gap: 0.75rem;
}

.search {
  display: flex;
  background-color: var(--inputColor);
  border-radius: 10px;
  padding: 5px;
}

.search>input {
  border: none;
  outline: none;
  background-color: transparent;
}

.s-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 5px;
  color: white;
  background: linear-gradient(98.63deg, #8f00ff 0%, #ff18b7 100%);
}

.s_icon2 {
  color: #8f00ff;
  margin-top: 10px;
  font-weight: bolder;
  font-size: large;
  text-transform: uppercase;
}

.s-icon:hover {
  cursor: pointer;
}