.infoCard {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: #242526;
  border-radius: 1rem;
  padding: 1rem;
  width: 90%;
}

.infoHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoHead>div:hover {
  cursor: pointer;
}

.logout-button {
  width: 7rem;
  height: 2rem;
  align-self: flex-end;
  margin-top: 6rem;
}