.post {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #242526;
  gap: 1rem;
}

.post>img {
  width: 100%;
  /* max-height: 38rem; */
  object-fit: cover;
  border-radius: 0.5rem;
}

.postReact {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}