:root {
  --yellow: #f5c32c;
  --orange: #b53131;
  --black: #242d49;
  --gray: rgba(36, 45, 73, 0.65);
  --profileShadow: 0px 4px 17px 2px rgba(0, 0, 0, 0.25);
  --hrColor: #cfcdcd;
  --cardColor: rgba(255, 255, 255, 0.64);
  --buttonBg: linear-gradient(98.63deg, #8f00ff 0%, #ff18b7 100%);
  --inputColor: rgba(40, 52, 62, 0.07);
  --photo: #4cb256;
  --video: #4a4eb7;
  --location: #ef5757;
  --shedule: #e1ae4a;
}

.App {
  overflow: auto;
  color: var(--black);
  background: #18191A;
}

body,
html {
  overflow: auto;
  margin: 0%;
  background: #18191A;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: var(--buttonBg);
  transition: all ms ease-out;
}

.icon_bg {
  color: whitesmoke;
}

.button:hover {
  cursor: pointer;
  color: var(--orange);
  background: transparent;
  border: 2px solid var(--orange);
}

.button:disabled {
  background: silver;
  pointer-events: none;
}

::-webkit-scrollbar {
  display: contents;
}

a,
b,
span,
h1,
h2,
h3,
h4,
h6 {
  text-decoration: none;
  color: white;
}