.trendCard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--cardColor);
  padding-left: 2rem;
}

.trend {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.trend > span:nth-of-type(1) {
  font-weight: bold;
}

.r-button {
  height: 3rem;
  width: 80%;
  align-self: center;
}
