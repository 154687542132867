.navbar_lg {
    display: none;
}

@media only screen and (max-width: 600px) {
    .navbar_sm {
        display: none;
    }

    .navbar_lg {
        display: inline;
    }
}